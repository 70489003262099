import React, { useState } from 'react';
import './NewSimulation.css';

const NewSimulation = ({ onCreateSimulation }) => {
  const [simulationName, setSimulationName] = useState('');
  const [users, setUsers] = useState(['']); // Start with one empty user field
  const [error, setError] = useState('');

  // Handle user input
  const handleUserChange = (index, value) => {
    const newUsers = [...users];
    newUsers[index] = value;

    // If the last field is filled and less than 25 users, add a new empty field
    if (index === users.length - 1 && value !== '' && users.length < 25) {
      newUsers.push('');
    }

    setUsers(newUsers);
  };

  // Handle form submission
  const handleSubmit = async () => {
    if (!simulationName.trim()) {
      setError('Simulation name is required');
      return;
    }

    const filteredUsers = users.filter((user) => user.trim() !== '');

    if (filteredUsers.length === 0) {
      setError('At least one user is required');
      return;
    }

    try {
      // Trigger simulation creation through the parent handler
      await onCreateSimulation({
        name: simulationName,
        people: filteredUsers,
      });
    } catch (err) {
      console.error('Error creating simulation:', err);
      setError('Failed to create simulation');
    }
  };

  return (
    <div className="NewSimulationContainer">
      <h2 className="NewSimulationTitle">Create New Simulation</h2>
      <div className="NewSimulationForm">
        {/* Simulation Name Input */}
        <input
          className="NewSimulationInput"
          type="text"
          placeholder="Simulation Name"
          value={simulationName}
          onChange={(e) => setSimulationName(e.target.value)}
        />

        {/* User Input Fields */}
        <div className="UserInputContainer">
          {users.map((user, index) => (
            <input
              key={index}
              className="NewSimulationInput UserInput"
              type="text"
              placeholder={`User ${index + 1}`}
              value={user}
              onChange={(e) => handleUserChange(index, e.target.value)}
            />
          ))}
        </div>

        {/* Error Message */}
        {error && <p className="ErrorMessage">{error}</p>}

        {/* Create Simulation Button */}
        <button className="CreateSimulationButton" onClick={handleSubmit}>
          Create Simulation
        </button>
      </div>
    </div>
  );
};

export default NewSimulation;