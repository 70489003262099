// Store the session ID outside of the function scope
let sessionId = null;

// Function to generate a unique session ID if one does not already exist
function generateSessionId() {
  if (!sessionId) {
    console.log("Generating new session id!")
    sessionId = 'session-' + Math.random().toString(36).substr(2, 9);
  }
  return sessionId;
}

export const converseWithClaude = (currentMessage, onDataReceived) => {
  // Generate or retrieve the session ID
  const currentSessionId = generateSessionId();
  
  const ws = new WebSocket('wss://websocket.api.casal.ai/wazuh');
  //const ws = new WebSocket('wss://vtil4rcte3.execute-api.us-east-1.amazonaws.com/production');

  ws.onopen = () => {
    const payload = {
      message: currentMessage, // Send only the current message
      sessionId: currentSessionId,    // Include the generated session ID
      knowledge_base: "web",
    };
    ws.send(JSON.stringify(payload));
  };

  ws.onmessage = (event) => {
    const data = event.data; // Keep the raw data
    onDataReceived(data); // Stream each received data (token or chunk)
  };

  ws.onclose = () => {
    console.log('WebSocket connection closed');
  };

  ws.onerror = (error) => {
    console.error('WebSocket error:', error);
  };
};