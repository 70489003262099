import React, { useState } from 'react';

export const formatText = (text, copyCode, lastCharRef, showDot) => {
  const parts = [];
  let remainingText = text;

  while (remainingText) {
    let codeMatch = remainingText.match(/```(\w*)/); // Match the start of a code block with optional language
    let boldMatch = remainingText.match(/\*\*/); // Match the start of bold text
    let bulletMatch = remainingText.match(/^[-*]\s+(.*)/m); // Match lines starting with "-" or "*"
    let linkMatch = remainingText.match(/\[([^\]]+)\]\(([^\s]+)\)/); // Match markdown links in the format [Text](URL), with optional http(s)
    let citationMatch = remainingText.match(/\(>>(?:https?:\/\/)?[^\s]+<<\)/); // Match the custom link format without requiring "http(s)"

    // Handle code blocks
    if (codeMatch && (!boldMatch || codeMatch.index < boldMatch.index) && (!bulletMatch || codeMatch.index < bulletMatch.index) && (!linkMatch || codeMatch.index < linkMatch.index) && (!citationMatch || codeMatch.index < citationMatch.index)) {
      if (codeMatch.index > 0) {
        parts.push(remainingText.slice(0, codeMatch.index));
      }
      const language = codeMatch[1] || null;
      const endOfBlock = remainingText.indexOf('```', codeMatch.index + 3);
      const codeContent = endOfBlock !== -1 
        ? remainingText.slice(codeMatch.index + codeMatch[0].length, endOfBlock)
        : remainingText.slice(codeMatch.index + codeMatch[0].length);

      parts.push(
        <CodeBlock
          key={parts.length}
          language={language}
          codeContent={codeContent}
          copyCode={copyCode}
          lastCharRef={lastCharRef}
          showDot={showDot && lastCharRef !== null}
        />
      );

      remainingText = endOfBlock !== -1
        ? remainingText.slice(endOfBlock + 3)
        : '';
    }
    // Handle bold text
    else if (boldMatch && (!bulletMatch || boldMatch.index < bulletMatch.index) && (!linkMatch || boldMatch.index < linkMatch.index) && (!citationMatch || boldMatch.index < citationMatch.index)) {
      if (boldMatch.index > 0) {
        parts.push(remainingText.slice(0, boldMatch.index));
      }
      const endOfBold = remainingText.indexOf('**', boldMatch.index + 2);
      const boldContent = endOfBold !== -1 
        ? remainingText.slice(boldMatch.index + 2, endOfBold)
        : remainingText.slice(boldMatch.index + 2);

      parts.push(
        <strong key={parts.length}>
          {boldContent}
        </strong>
      );

      remainingText = endOfBold !== -1
        ? remainingText.slice(endOfBold + 2)
        : '';
    }
    // Handle bullet points (lists)
    else if (bulletMatch && (!linkMatch || bulletMatch.index < linkMatch.index) && (!citationMatch || bulletMatch.index < citationMatch.index)) {
      if (bulletMatch.index > 0) {
        parts.push(remainingText.slice(0, bulletMatch.index));
      }

      const formattedBulletContent = formatText(bulletMatch[1], copyCode, lastCharRef, showDot);

      parts.push(
        <BulletPoint key={parts.length} lastCharRef={lastCharRef} showDot={showDot && lastCharRef !== null}>
          {formattedBulletContent}
        </BulletPoint>
      );

      remainingText = remainingText.slice(bulletMatch.index + bulletMatch[0].length);
    }
    // Handle markdown links
    else if (linkMatch && (!citationMatch || linkMatch.index < citationMatch.index)) {
      if (linkMatch.index > 0) {
        parts.push(remainingText.slice(0, linkMatch.index));
      }

      const linkText = linkMatch[1]; // Extract the text for the link
      const linkUrl = linkMatch[2];  // Extract the URL
      console.log("LINK URL:", linkUrl)

      parts.push(
        <a href={linkUrl} target="_blank" rel="noopener noreferrer" key={parts.length}>
          {linkText}
        </a>
      );

      remainingText = remainingText.slice(linkMatch.index + linkMatch[0].length);
    }
    // Handle citations
    else if (citationMatch) {
      if (citationMatch.index > 0) {
        parts.push(remainingText.slice(0, citationMatch.index));
      }

      let citationContent = citationMatch[0].slice(3, -3); // Extract the citation URL from (>>link<<)

      if (citationContent.startsWith('https//')) {
        // Correcting the format from 'https//' to 'https://'
        citationContent = 'https://' + citationContent.slice(7); // Skip the incorrect 'https//' part
      } else if (!citationContent.startsWith('http://') && !citationContent.startsWith('https://')) {
        // If it doesn't start with 'http://' or 'https://', add 'https://'
        citationContent = 'https://' + citationContent;
      }

      parts.push(
        <LinkImageButton key={parts.length} href={citationContent} />
      );

      remainingText = remainingText.slice(citationMatch.index + citationMatch[0].length);
    }
    // Handle plain text
    else {
      parts.push(<span key={parts.length}>{remainingText}</span>);
      break;
    }
  }

  return parts.flatMap((part, index) => 
    typeof part === 'string'
      ? part.split('\n').map((line, i) => (
          <React.Fragment key={`${index}-${i}`}>
            {line}
            {i < part.split('\n').length - 1 && <br />}
          </React.Fragment>
        ))
      : part
  );
};

// BulletPoint Component
const BulletPoint = ({ children, lastCharRef, showDot }) => (
  <div className="bullet-point">
    <span className="bullet-dot"></span>
    <span className="bullet-text" ref={lastCharRef}>
      {children}
      {showDot && <span className="BlueDot" />}
    </span>
  </div>
);

// CodeBlock Component
const CodeBlock = ({ language, codeContent, copyCode, lastCharRef, showDot }) => {
  const [buttonText, setButtonText] = useState("Copy");

  const handleCopy = () => {
    copyCode(codeContent);
    setButtonText("Copied");
    setTimeout(() => {
      setButtonText("Copy");
    }, 1500);
  };

  return (
    <div className="code-block">
      {language && <div className="code-language">{language}</div>}
      <pre>
        <code ref={lastCharRef}>
          {codeContent}
          {showDot && <span className="BlueDot" />}
        </code>
      </pre>
      <button className="copy-button" onClick={handleCopy}>
        {buttonText}
      </button>
    </div>
  );
};

// LinkImageButton Component
const LinkImageButton = ({ href }) => {
  return (
    <a href={href} className="link-image-button" target="_blank" rel="noopener noreferrer">
      <img src="https://resources.casal.ai/icons/WazuhCitation.png" alt="Link Button" />
    </a>
  );
};