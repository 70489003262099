import React, { useState, useEffect, useRef } from 'react';
import './global.css';
import { converseWithClaude } from './support/bedrockService';
import StatusIndicator from './components/StatusIndicator';
import MessageList from './components/MessageList';
import MessageInput from './components/MessageInput';
import { fetchChatHistory } from './support/api'; // Import the fetchChatHistory function
import { connectToWebSocket, sendMessage } from './support/dehWebsocketHandler'; // WebSocket handling

// Import the streaming service
import {
  handleStartStream,
  handleStreamToken,
  handleEndStream,
  handleUserMessage
} from './support/dehStreamingService';

function DehDemo() {
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [status, setStatusMessage] = useState('');
  const [contextArray, setContextArray] = useState([]);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isFirstTokenReceived, setIsFirstTokenReceived] = useState(false);
  const [simulationUsers, setSimulationUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Loading state for the API
  const messageListRef = useRef(null);

  const simulationId = sessionStorage.getItem('currentSimulationId');
  const storedUsers = sessionStorage.getItem('simulationUsers');

  // WebSocket API URL (replace this with your WebSocket API Gateway URL)
  const websocketApiUrl = "wss://websocket.api.casal.ai/deh";

  // Parse query string to get the starting user
  const urlParams = new URLSearchParams(window.location.search);
  const startingUser = urlParams.get('user');

  // Redirect to /deh/overview if any required data is missing
  useEffect(() => {
    if (!simulationId || !storedUsers) {
      window.location.href = '/deh/overview';
    } else {
      setSimulationUsers(JSON.parse(storedUsers));
      if (startingUser) {
        setSelectedUser(startingUser);
      }
    }
  }, [simulationId, storedUsers, startingUser]);

  // Fetch chat history for the selected user using the imported fetchChatHistory function
  useEffect(() => {
    const loadChatHistory = async () => {
      if (selectedUser && simulationId) {
        setMessages([]); // Clear existing messages when switching users
        setIsLoading(true); // Show loading indicator

        try {
          const data = await fetchChatHistory(simulationId);

          const user = data.simulation.people.find((person) => person.name === selectedUser);

          if (user && user.chat) {
            const chatHistory = user.chat.map((message) => ({
              text: message.content,
              direction: message.role === 'user' ? 'outgoing' : 'incoming',
            }));
            setMessages(chatHistory);
          } else {
            setMessages([]); // Clear chat window if no history is found
          }
        } catch (err) {
          console.error('Error fetching chat history:', err);
        } finally {
          setIsLoading(false); // Hide loading indicator after API call
        }
      }
    };

    loadChatHistory();
  }, [selectedUser, simulationId]);

  // Connect to WebSocket on component mount
  useEffect(() => {
    connectToWebSocket(websocketApiUrl, handleIncomingMessage);
  }, []);

  const handleIncomingMessage = (data) => {
    console.log("INcoming Message!", data.action)
    switch (data.action) {
      case 'usermessage':
        handleUserMessage(data.message, setMessages);
        break;

      case 'startstream':
        handleStartStream(setMessages);
        break;

      case 'stream':
        handleStreamToken(data.token, setMessages);
        break;

      case 'endstream':
        handleEndStream(setMessages, setIsGenerating);
        break;

      default:
        console.error("Unknown WebSocket route:", data.action);
    }
  };

  const handleSend = async () => {
    if (inputValue.trim() === '' || isGenerating) return;

    // Disable the input while waiting for response
    setIsGenerating(true);

    // Send the user's message via WebSocket
    sendMessage('newmessage', { message: inputValue, user: selectedUser });

    // Clear the input field
    setInputValue('');
  };

  const scrollToBottom = () => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const copyCode = (text) => {
    navigator.clipboard.writeText(text);
  };

  // Redirect to /deh/overview when the home button is clicked
  const handleHomeClick = () => {
    window.location.href = '/deh/overview';
  };

  return (
    <div className="App deh-demo">
      <img src="https://resources.casal.ai/developer/client-logos/deh_online09102024.png" className="App-logo" alt="logo" />

      {/* Home button */}
      <button className="home-button" onClick={handleHomeClick}>
        <img src="https://resources.casal.ai/icons/home.svg" alt="Home" className="home-icon" />
      </button>

      <div className="ChatContainer deh-demo">
        <MessageList
          messages={messages}
          copyCode={copyCode}
          messageListRef={messageListRef}
          isGenerating={isGenerating}
          isFirstTokenReceived={isFirstTokenReceived}
        />
        <div className="ChatBarBackground"></div> {/* Background rectangle for floating effect */}
        <MessageInput
          inputValue={inputValue}
          setInputValue={setInputValue}
          handleSend={handleSend}
          isGenerating={isGenerating}
          dehDemo={true}
          simulationUsers={simulationUsers} // Pass simulation users to the dropdown
          selectedUser={selectedUser}       // Pass the selected user
          setSelectedUser={setSelectedUser} // Pass the setSelectedUser function to update user on change
        />
      </div>
      <StatusIndicator status={status} />
    </div>
  );
}

export default DehDemo;