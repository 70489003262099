import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import WazuhDemo from './WazuhDemo'; // Import the WazuhDemo component
import DehDemoChat from './DehDemoChat'; // Import SinVisitasDemo component
import DehDemoOverview from './DehDemoOverview'; // Import SinVisitasDemo component

function App() {
  return (
    <Router>
      <Routes>
        {/*<Route path="/" element={<Navigate to="/wazuh" />} />*/}
        <Route path="/deh-demo" element={<Navigate to="/deh/overview" />} />
        <Route path="/deh-demo/overview" element={<Navigate to="/deh/overview" />} />

        {/* Main Chat App Route */}
        <Route path="/wazuh" element={<WazuhDemo />} />
        
        <Route path="/deh/chat" element={<DehDemoChat />} />

        <Route path="/deh/overview" element={<DehDemoOverview />} />
      </Routes>
    </Router>
  );
}

export default App;