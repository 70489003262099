import React, { useState, useEffect, useRef } from 'react';
import './global.css';
import { converseWithClaude } from './support/bedrockService';
import { handleStreamingData } from './support/streamingService';
import StatusIndicator from './components/StatusIndicator';
import MessageList from './components/MessageList';
import MessageInput from './components/MessageInput';

function WazuhDemo() {
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [status, setStatusMessage] = useState('');
  const [contextArray, setContextArray] = useState([]);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isFirstTokenReceived, setIsFirstTokenReceived] = useState(false); // State to track if the first token has been received
  const messageListRef = useRef(null);

  const handleSend = async () => {
    if (inputValue.trim() === '' || isGenerating) return;

    const newMessage = { text: inputValue, direction: 'outgoing' };
    setMessages((prevMessages) => [...prevMessages, newMessage]);
    setInputValue('');
    setIsGenerating(true);
    setIsFirstTokenReceived(false); // Reset for the new message

    // Add an empty incoming message to show the dot immediately
    setMessages((prevMessages) => [...prevMessages, { text: '', direction: 'incoming', isLoading: true }]);

    try {
      setStatusMessage('thinking');

      await converseWithClaude(inputValue, (data) => {
        handleStreamingData(data, setMessages, setStatusMessage, setContextArray, setIsGenerating, setIsFirstTokenReceived);
      });
    } catch (err) {
      console.error("Error during conversation:", err);
      setIsGenerating(false); // Ensure we reset the generating state in case of error
    }
  };

  const scrollToBottom = () => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const copyCode = (text) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <div className="App wazuh-demo">
      <img src="https://resources.casal.ai/developer/client-logos/wazuh_ai09102024.png" className="App-logo" alt="logo" />
      <div className="ChatContainer">
        <MessageList
          messages={messages}
          copyCode={copyCode}
          messageListRef={messageListRef}
          isGenerating={isGenerating}
          isFirstTokenReceived={isFirstTokenReceived}
        />
        <div className="ChatBarBackground"></div> {/* Background rectangle for floating effect */}
        <MessageInput
          inputValue={inputValue}
          setInputValue={setInputValue}
          handleSend={handleSend}
          isGenerating={isGenerating}
        />
      </div>
      <StatusIndicator status={status} />
    </div>
  );
}

export default WazuhDemo;