import React, { useRef, useEffect, useState } from 'react';
import './MessageList.css';
import { formatText } from '../support/formattingService.js';

const MessageList = ({ messages, copyCode, messageListRef, isGenerating, isFirstTokenReceived }) => {
  const lastCharRef = useRef(null);
  const [isInCodeOrList, setIsInCodeOrList] = useState(false); // Flag to track if streaming is inside code block or list

  // Scroll to the last character when messages are updated
  useEffect(() => {
    if (lastCharRef.current && isGenerating) {
      lastCharRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages, isGenerating]);

  // Effect to toggle `isInCodeOrList` based on current context
  useEffect(() => {
    if (!isGenerating) {
      // When generation stops, reset `isInCodeOrList` to false
      setIsInCodeOrList(false);
    }
  }, [isGenerating]);

  return (
    <div className="MessageList deh-demo" ref={messageListRef}>
      {messages.map((msg, index) => {
        const isLastMessage = index === messages.length - 1;
        const showDot = msg.direction === 'incoming' && isGenerating && isLastMessage;

        // Format the text of the message
        const formattedText = formatText(msg.text, copyCode, lastCharRef, showDot, setIsInCodeOrList);

        return (
          <div
            key={index}
            className={`Message ${msg.direction === 'outgoing' ? 'outgoing' : 'incoming'}`}
          >
            {formattedText}

            {/* Main pulsing blue dot is hidden while inside code block or list */}
            {!isInCodeOrList && showDot && !isFirstTokenReceived && (
              <span className="BlueDot pulsing" />
            )}

            {/* Solid blue dot shown at the end if not inside code block or list */}
            {!isInCodeOrList && showDot && isFirstTokenReceived && (
              <span className="BlueDot" ref={lastCharRef} />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default MessageList;