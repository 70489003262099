import React from 'react';
import './StatusIndicator.css';

const StatusIndicator = ({ status }) => {
  return (
    <>
      {status === 'thinking' && (
        <div className="StatusIndicator Thinking">
          Understanding Question
        </div>
      )}
      {status === 'searchingDocumentation' && (
        <div className="StatusIndicator Documentation">
          Searching Wazuh Documentation
        </div>
      )}
      {status === 'searchingSupport' && (
        <div className="StatusIndicator Support">
          Searching Reddit
        </div>
      )}
      {status === 'ruleResponse' && (
        <div className="StatusIndicator RuleDecoder">
          Analyzing Rule Guidelines
        </div>
      )}
      {status === 'decoderResponse' && (
        <div className="StatusIndicator RuleDecoder">
          Analyzing Decoder Guidelines
        </div>
      )}
      {status === 'doneSearchingDatabase' && (
        <div className="StatusIndicator Done">
          Finished Searching Documentation
        </div>
      )}
    </>
  );
};

export default StatusIndicator;