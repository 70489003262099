/**
 * Handles the beginning of streaming from the assistant
 */
export const handleStartStream = (setMessages) => {
    setMessages((prevMessages) => [
      ...prevMessages,
      { text: '', direction: 'incoming', isLoading: true }
    ]);
  };
  
  /**
   * Handles streaming of tokens from the assistant
   */
  export const handleStreamToken = (token, setMessages) => {
    setMessages((prevMessages) => {
      const newMessages = [...prevMessages];
      const lastMessageIndex = newMessages.length - 1;
  
      // Ensure the last message is still in a loading state
      if (lastMessageIndex >= 0 && newMessages[lastMessageIndex].isLoading) {
        newMessages[lastMessageIndex].text += token; // Append the token to the last loading message
      }
      
      return newMessages;
    });
  };
  
  /**
   * Handles completion of streaming from the assistant
   */
  export const handleEndStream = (setMessages, setIsGenerating) => {
    setMessages((prevMessages) => {
      const newMessages = [...prevMessages];
      const lastMessageIndex = newMessages.length - 1;
  
      // Ensure the last message is still in a loading state
      if (lastMessageIndex >= 0 && newMessages[lastMessageIndex].isLoading) {
        newMessages[lastMessageIndex].isLoading = false; // Mark the message as fully loaded
      }
      
      return newMessages;
    });
  
    setIsGenerating(false); // Allow new messages to be sent
  };
  
  /**
   * Handles receiving a user message
   */
  export const handleUserMessage = (message, setMessages, setIsGenerating) => {
    // Add the incoming message in the loading state and prevent further messages from being sent
    setMessages((prevMessages) => [
      ...prevMessages,
      { text: message, direction: 'outgoing', isLoading: true }
    ]);
  
    // Disable the input by setting isGenerating to true
    setIsGenerating(true);
  };