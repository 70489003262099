import React from 'react';
import './LoadSimulation.css';

const LoadSimulation = ({ simulations, onViewSummary, onOpenChat }) => {
  return (
    <div className="SimulationList">
      {simulations.length > 0 ? (
        simulations.map((simulation) => (
          <div key={simulation.simulation_id} className="SimulationCard">
            <div className="SimulationHeaderCard">
              <h3 className="SimulationName">{simulation.simulation_name}</h3>

              {/* View Summary button */}
              <button
                className="ViewSummaryButton"
                onClick={() => onViewSummary(simulation.simulation_id, simulation.people)}
              >
                View Summary
              </button>
            </div>

            {/* List of People */}
            <div className="PeopleList">
              {simulation.people && simulation.people.length > 0 ? (
                simulation.people.map((person) => (
                  <button
                    key={person.name}
                    className="PersonCard"
                    onClick={() => onOpenChat(simulation.simulation_id, simulation.people, person.name)}
                  >
                    <span className="PersonName">{person.name}</span>
                    <span className="ChatCount">{person.chat_count} Chats</span>
                  </button>
                ))
              ) : (
                <p>No people in this simulation.</p>
              )}
            </div>
          </div>
        ))
      ) : (
        <p>No simulations available.</p>
      )}
    </div>
  );
};

export default LoadSimulation;