import React from 'react';
import './MessageInput.css';

const MessageInput = ({ inputValue, setInputValue, handleSend, isGenerating, dehDemo = false, simulationUsers = [], selectedUser, setSelectedUser }) => {

  return (
    <div className="MessageInputContainer">
      {dehDemo && (
        <select
          className="DropdownMenu"
          value={selectedUser} // Bind the value to selectedUser
          onChange={(e) => setSelectedUser(e.target.value)} // Update selectedUser when the user changes the selection
        >
          {simulationUsers.map((user) => (
            <option key={user} value={user}>
              {user}
            </option>
          ))}
        </select>
      )}
      <input
        type="text"
        className="MessageInput"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyPress={(e) => e.key === 'Enter' && handleSend()}
        placeholder="Type your message here..."
        disabled={isGenerating}
      />
      <button onClick={handleSend} className={`SendButton ${isGenerating ? 'disabled' : ''}`}>
        Send
      </button>
    </div>
  );
};

export default MessageInput;