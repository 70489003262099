// Function to fetch all simulations
export const fetchSimulations = async () => {
    const response = await fetch('https://api.casal.ai/deh/demo/simulation-manager', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        action: 'get-simulations'  // API action to fetch all simulations
      })
    });
  
    if (!response.ok) {
      throw new Error('Failed to fetch simulations');
    }
  
    const data = await response.json();
    return data.simulations; // Assuming the response has a "simulations" field
  };
  
  // Function to create a new simulation
  export const createSimulation = async (simulationData) => {
    const response = await fetch('https://api.casal.ai/deh/demo/simulation-manager', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        action: 'create-simulation',  // API action to create a simulation
        simulation_data: simulationData,  // Data for the new simulation
      }),
    });
  
    if (!response.ok) {
      throw new Error('Failed to create simulation');
    }
  
    return await response.json();
  };

  // Function to fetch chat history for a specific user in a simulation
export const fetchChatHistory = async (simulationId) => {
  const payload = {
    action: 'load-simulation',
    simulation_id: simulationId,
  };

  const response = await fetch('https://api.casal.ai/deh/demo/simulation-manager', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

  if (!response.ok) {
    throw new Error('Failed to fetch chat history');
  }

  return await response.json();
};