// Token queue to store tokens with timestamps
let tokenQueue = [];
let isProcessing = false;
let batchTimeout = null; // Timeout for batching

export const handleStreamingData = (data, setMessages, setStatusMessage, setContextArray, setIsGenerating, setIsFirstTokenReceived) => {
    try {
        const parsedData = JSON.parse(data);

        if (parsedData.type === "status") {
            console.log("Status update:", parsedData.content);
            if (parsedData.content === "finishedUserQuery") {
                setIsGenerating(false); // Re-enable input and stop dot pulsing after generation is done
            } else {
                setStatusMessage(parsedData.content);
            }
        } else if (parsedData.type === "content") {
            const tokenTimestamp = new Date().toISOString();
            setStatusMessage("");
            //console.log("Token received at", tokenTimestamp, ":", parsedData.content);

            // Push the token into the queue with its timestamp
            tokenQueue.push({ token: parsedData.content, timestamp: tokenTimestamp });

            // Start processing the queue, batching tokens
            processQueue(setMessages, setStatusMessage, setIsGenerating, setIsFirstTokenReceived);
        } else if (parsedData.type === "context") {
            console.log("Context data received:", parsedData.content);
            setStatusMessage("doneSearchingDatabase");
            setContextArray((prevContext) => {
                if (prevContext.length >= 5) return prevContext; // Limit to 5 pieces of context
                return [...prevContext, parsedData.content];
            });
        }
    } catch (err) {
        console.error("Error parsing streaming data:", err);
    }
};

// Function to process tokens in the correct order and in batches
const processQueue = (setMessages, setStatusMessage, setIsGenerating, setIsFirstTokenReceived) => {
    if (isProcessing) return; // Skip if already processing

    isProcessing = true;

    if (batchTimeout) {
        clearTimeout(batchTimeout); // Clear any existing timeout if already set
    }

    // Set a timeout to process the batch of tokens
    batchTimeout = setTimeout(() => {
        if (tokenQueue.length === 0) {
            isProcessing = false;
            return; // Exit if there's nothing to process
        }

        // Get all tokens from the queue
        const tokensToProcess = [...tokenQueue];
        tokenQueue = []; // Clear the queue after collecting tokens

        // Combine all tokens into a single string
        const combinedTokens = tokensToProcess.map(t => t.token).join('');

        // Log the processing of the batch
        const lastTokenTimestamp = tokensToProcess[tokensToProcess.length - 1].timestamp;
        //console.log("Processing batch at", lastTokenTimestamp, "with tokens:", combinedTokens);

        // Update the messages with the combined tokens
        setMessages((prevMessages) => {
            // Ensure prevMessages is an array and not undefined
            prevMessages = prevMessages || [];

            let updatedMessages;
            const lastMessage = prevMessages[prevMessages.length - 1];

            //console.log("Message update triggered at", new Date().toISOString(), "for batch received at", lastTokenTimestamp);

            // First token handling: new message
            if (prevMessages.length === 0 || lastMessage.direction !== "incoming") {
                updatedMessages = [...prevMessages, { text: combinedTokens, direction: "incoming", timestamp: lastTokenTimestamp }];
            } 
            // Append to the last message if it's incoming and avoid duplicates
            else {
                // Check if the last message already contains the token
                if (!lastMessage.text.endsWith(combinedTokens)) {
                    lastMessage.text += combinedTokens;
                    updatedMessages = [...prevMessages.slice(0, -1), lastMessage];
                } else {
                    updatedMessages = [...prevMessages]; // No change if the token is already there
                }
            }

            // Ensure the first token received flag is updated
            if (!setIsFirstTokenReceived.current) {
                setIsFirstTokenReceived(true);
            }

            return updatedMessages;
        });

        // Reset the processing flag
        isProcessing = false;

        // If there are more tokens, process them
        if (tokenQueue.length > 0) {
            processQueue(setMessages, setStatusMessage, setIsGenerating, setIsFirstTokenReceived);
        }
    }, 100); // Process tokens every 100ms (batching them)
};