let ws = null;

export const connectToWebSocket = (apiUrl, onMessageReceived) => {
  if (!ws) {
    ws = new WebSocket(apiUrl);
  }

  ws.onopen = () => {
    console.log("Connected to WebSocket");
  };

  ws.onclose = () => {
    console.log("Disconnected from WebSocket");
    ws = null; // Reset WebSocket on disconnect
  };

  ws.onmessage = (event) => {
    console.log("MESSAE!", event.data)
    const data = JSON.parse(event.data);
    console.log("Received message:", data);

    // Trigger the callback with the received message
    if (onMessageReceived) {
      onMessageReceived(data);
    }
  };

  ws.onerror = (error) => {
    console.error("WebSocket error:", error);
  };
};

export const sendMessage = (route, message) => {
  if (ws && ws.readyState === WebSocket.OPEN) {
    const payload = {
      action: route, // WebSocket route (e.g., "outgoing-message", "begin-assistant-message")
      ...message,    // Attach the message payload
    };
    ws.send(JSON.stringify(payload));
  } else {
    console.error("WebSocket is not connected.");
  }
};