import React from 'react';

const Summary = () => {
  return (
    <div>
      <h2>Summary Menu</h2>
      {/* Your logic for summarizing simulations */}
    </div>
  );
};

export default Summary;