import React, { useState, useEffect } from 'react';
import './DehDemoOverview.css';
import { fetchSimulations, createSimulation } from './support/api'; // Import API functions
import LoadSimulation from './components/dehDemo/LoadSimulation';
import NewSimulation from './components/dehDemo/NewSimulation';
import Summary from './components/dehDemo/Summary';
import { useNavigate } from 'react-router-dom';

const DehDemoOverview = ({ currentSimulationName }) => {
  const [selectedMenu, setSelectedMenu] = useState('load'); // Default to New Simulation
  const [simulations, setSimulations] = useState([]); // Store fetched simulations
  const [loading, setLoading] = useState(true); // Loading state to handle fetching
  const [dataFetched, setDataFetched] = useState(false); // Track if data has been fetched
  const navigate = useNavigate();

  // Function to fetch simulations and refresh the view
  const refreshData = async () => {
    setLoading(true); // Set loading to true while fetching data
    try {
      const response = await fetchSimulations();
      setSimulations(response || []); // Ensure it is always an array
      setDataFetched(true); // Mark data as fetched
    } catch (error) {
      console.error("Error fetching simulations:", error);
    } finally {
      setLoading(false); // Set loading to false when data fetching is complete
    }
  };

  // Handle simulation creation
  const handleCreateSimulation = async (simulationData) => {
    try {
      setLoading(true); // Set loading to true while creating simulation
      const createdSimulation = await createSimulation(simulationData); // Call the API to create simulation

      // Save the created simulation ID and users to sessionStorage
      saveSimulationDataToSession(createdSimulation.id, createdSimulation.users);

      await refreshData(); // Refresh the simulations after creating
      setSelectedMenu('summary'); // Switch to View Summary menu after creation
    } catch (error) {
      console.error("Error creating simulation:", error);
    } finally {
      setLoading(false); // Set loading to false after simulation creation
    }
  };

  // Save simulation ID and users in sessionStorage
  const saveSimulationDataToSession = (simulationId, users) => {
    sessionStorage.setItem('currentSimulationId', simulationId);
    sessionStorage.setItem('simulationUsers', JSON.stringify(users.map(user => user.name))); // Save user names as a string list
  };

  // Handle View Summary button click
  const handleViewSummary = (simulationId, users) => {
    saveSimulationDataToSession(simulationId, users); // Save simulation ID and users to sessionStorage
    setSelectedMenu('summary'); // Switch to Summary view
  };

  // Handle opening a person's chat (save the simulation and users)
  const handleOpenChat = (simulationId, users, user) => {
    saveSimulationDataToSession(simulationId, users); // Save simulation ID and users to sessionStorage
    // Logic to open chat can go here
    console.log('Opening chat for simulation:', simulationId);
    navigate(`/deh/chat?user=${user}`);
  };

  // Only fetch simulations on the first load or when manually triggered
  useEffect(() => {
    if (!dataFetched) {
      refreshData();
    }
  }, [dataFetched]);

  return (
    <div className="DehDemoOverview">
      {/* Left-side navigation bar */}
      <div className="NavigationBar">
        <img src="https://resources.casal.ai/developer/client-logos/deh_online09102024.png" className="DehLogo" alt="DEH Logo" />
        <div className="SimulationInfo">
          <div className="SimulationHeader">
            <h2>Current Simulation</h2>
          </div>
          <p>{currentSimulationName || "No Simulation Loaded"}</p>
        </div>
        <div className="NavButtons">
          <button
            onClick={() => setSelectedMenu('new')}
            className={`NavButton ${selectedMenu === 'new' ? 'selected' : ''}`}
          >
            New Simulation
          </button>
          <button
            onClick={() => setSelectedMenu('load')}
            className={`NavButton ${selectedMenu === 'load' ? 'selected' : ''}`}
          >
            Load Simulation
          </button>
          <button
            onClick={() => handleViewSummary(currentSimulationName, simulations)}
            className={`NavButton ${selectedMenu === 'summary' ? 'selected' : ''}`}
          >
            View Summary
          </button>
        </div>
      </div>

      {/* Main content area */}
      <div className="ContentArea">
        {loading ? (
          <p>Loading...</p>
        ) : (
          <>
            {selectedMenu === 'summary' && <Summary />}
            {selectedMenu === 'new' && <NewSimulation onCreateSimulation={handleCreateSimulation} />}
            {selectedMenu === 'load' && (
              <LoadSimulation
                simulations={simulations}
                onViewSummary={handleViewSummary} // Pass down the handleViewSummary function
                onOpenChat={handleOpenChat} // Pass down the handleOpenChat function
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default DehDemoOverview;